<template>
    <div class="comments">
        <div
            class="comments__wrap"
            v-if="currentComments && currentComments.length"
        >
            <div
                v-if="comments.count > 3 && !!nextComments"
                class="comments__more"
                :class="{'hidden' : comments.count <= 3}"
            >
                <a
                    v-if="!isCommentsLoading"
                    class="comments__expand"
                    href="javascript:void(0)"
                    @click="loadComments"
                >
                    Показать предыдущие комментарии
                </a>

                <img
                    v-else
                    class="comments__loading"
                    height="8px"
                    alt="Загрузка"
                    src="../../../assets/animation/loading-comments.gif"
                >
            </div>

            <comment
                class="comments__item"
                v-for="comment in currentComments"
                :key="`comment_${comment.id}`"
                :id="`comment_${comment.id}`"
                :obj-id="objId"
                :comment="comment"
                :content-type="contentType"
                :update-comments-count="updateCommentsCount"
            />
        </div>

        <new-comment
            :is-cancel="false"
            :content-type="contentType"
            :obj-id="objId"
            :on-after-comment="onAfterComment"
        />
    </div>
</template>

<script>
    import session from '@/api/session';

    export default {
        name: 'Comments',
        components: {
            Comment: () => import('./Comment'),
            NewComment: () => import('./NewComment')
        },
        props: {
            comments: {
                type: Object,
                required: true
            },
            objId: {
                type: Number,
                required: true
            },
            contentType: {
                type: String,
                required: true
            },
            updateCommentsCount: {
              type: Function
            }
        },
        data() {
            return {
                nextComments: this.comments.count > 3 ? `/api/v1/comment/load/?contentType=${this.contentType}&id=${this.objId}` : null,
                currentComments: this.comments.results,
                isCommentsLoading: false
            };
        },
        methods: {
            async loadComments() {
                this.isCommentsLoading = true;
                try {
                    const request = session.get(this.nextComments);
                    const response = await request;

                    this.nextComments = response.data.next;
                    if (response.data.results) {
                        if (this.currentComments.length < 10) {
                            this.currentComments = response.data.results;
                        } else {
                            this.currentComments.unshift(...response.data.results);
                        }
                    }
                } catch (e) {
                    this.$swal('Ошибка!', 'При загрузке комментариев произошла ошибка на сервере', 'error');
                    console.error(e);
                }
                this.isCommentsLoading = false;
            },

            async onAfterComment(comment) {
                try {
                    /*
                        setTimeout для того, чтобы у поста не было бага со временем в будущем,
                        иначе у времени поста будет написано "через несколько секунд" после создания
                    */
                    setTimeout(async () => {
                        this.currentComments.push(comment);
                        this.updateCommentsCount(this.contentType);
                    }, 250);
                } catch (e) {
                    this.$swal('Ошибка!', 'При отправке комментария произошла ошибка на сервере', 'error');
                    console.error(e);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "#sass/v-style";

    .comments {
        margin-top: 30px;
        padding-bottom: 30px;

        &__expand {
            display: flex;
            align-items: center;
            color: #2173D3;
            &:hover {
                text-decoration: underline;
            }
        }

        &__more {
            display: block;
            margin-bottom: 30px;
        }

        &__loading {
            height: 8px;
        }

        &__item:nth-child(n+2) {
            margin-top: 25px;
        }

        &__wrap {
            margin-bottom: 30px;
        }
    }
</style>
